@font-face {
    font-family: ooredoobold;
    src:
      url(./ooredoo/ooredoo-Bold-webfont.eot?#iefix)format('woff'),
      url(./ooredoo/ooredoo-Bold-webfont.ttf) format('truetype'),
      url(./ooredoo/ooredoo-Bold-webfont.svg#ooredoobold) format('svg')
  }
  
  @font-face {
    font-family: ooredooregular;
    src:
      url(./ooredoo/ooredoo-Regular-webfont.eot?#iefix) format('woff'),
      url(./ooredoo/ooredoo-Regular-webfont.ttf) format('truetype'),
      url(./ooredoo/ooredoo-Regular-webfont.svg#ooredooregular) format('svg')
  }
  
  @font-face {
    font-family: ooredoolight;
    src:
      url(./ooredoo/ooredoo-Light.eot?#iefix) format('woff'),
      url(./ooredoo/ooredoo-Light.ttf) format('truetype'),
      url(./ooredoo/ooredoo-Light.svg#ooredooregular) format('svg')
  }