.p-fileupload-content {
    position: relative!important;
    font-family: ooredooRegular !important;
    font-size: medium;
}

.p-fileupload-row {
    display: flex!important;
    align-items: center!important;
}

.p-fileupload-row > div {
    flex: 1 1 auto!important;
    width: 25%!important;
}

.p-fileupload-row > div:last-child {
    text-align: right!important;
}

.p-fileupload-content > .p-progressbar {
    width: 100%!important;
    position: absolute!important;
    top: 0!important;
    left: 0!important;
}

.p-button.p-fileupload-choose {
    position: relative!important;
    overflow: hidden!important;
}

.p-button.p-fileupload-choose input[type=file] {
    display: none!important;
}

.p-fileupload-choose.p-fileupload-choose-selected input[type=file] {
    display: none!important;
}

.p-fileupload-filename {
    word-break: break-all!important;
}

.p-fluid .p-fileupload .p-button {
    width: auto!important;
}
.p-d-flex {
    display: -ms-flexbox!important;
    display: flex!important;
}
.p-ai-center {
    -ms-flex-align: center;
    align-items: center;
}
.p-dir-col {
    -ms-flex-direction: column;
    flex-direction: column;
}
.p-text-left {
    text-align: left!important;
}
.p-ml-3 {
    margin-left: 1rem!important;
}

.p-progressbar-determinate .p-progressbar-label {
    text-align: center!important;
    height: 100%!important;
    width: 100%!important;
    position: absolute!important;
    font-weight: 700!important;
    font-size: small!important;
}

.p-progressbar .p-progressbar-label {
    color: #1b66b1!important;
    line-height: 1.5rem!important;
}
.p-progressbar .p-progressbar-label {
    display: flow!important;
}
.p-progressbar {
    border: 0 none!important;
    background: #dee2e6!important;
    border-radius: 3px!important;
}