.p-dialog .p-dialog-header {
    border-bottom: 0 none;
    padding: 0.5rem !important;
    background: #f1f1f1f2 !important;
    font-size: small !important;
    font-family: "ooredoobold";
}

.p-dialog .p-dialog-header .p-dialog-title {
    font-size: 1rem !important;
    padding: .25em .5rem !important;
    font-weight: 700 !important;
}

.container-popup {
    padding-top: 1rem !important;
}

.p-dialog .p-dialog-footer {
    border-top: 0 none;
    background: #ffffff !important;
}

.sticked-popup {
    padding: 0 !important;
}

.p-dialog-content {
    overflow-x: hidden;
}
.custom-dialog-small-large{
    min-width: 40em!important;
}