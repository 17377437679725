@import "./colors.scss";
@import "../fonts/index.scss";

.login-page {
  color: var(--text-color) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  //background-image: linear-gradient(to right,#FFFFF7, rgba(192, 192, 192, 0.51), rgba(241, 241, 241, 0.42),  #FFFFF7);
}

.centre-elm {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15vh;
}

.Grttitre {
  color: $ooredooRedColor;
}

.custom-btn {
  background-color: $ooredooRedColor;
  border-color: $ooredooRedColor;
}

.p-datatable .p-datatable-loading-icon {
  color: $ooredooRedColor !important;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
  /* font-size: 2rem; */
  color: rgba(220, 220, 220, 0) !important;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  font-size: small !important;
}

.p-toast .p-toast-message .p-toast-message-content {
  padding: 0.5rem;
  border-width: 0 0 0 0;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: $ooredooRedColor;
  border-color: $ooredooRedColor;
}

.btn-primary:hover {
  color: #fff;
  background-color: $ooredooRedColorfonc;
  border-color: $ooredooRedColorfonc;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: $ooredooRedColorfonc;
  background-color: $ooredooRedColorfonc;
}

.contenant {
  position: relative;
  text-align: center;
}

.texte_centrer {
  color: #676262;
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cs-titre {
  justify-content: center;
  text-align: center;
  font-size: small;
  padding-top: 0.125rem;
  padding-bottom: 0.225rem;
}

.cs-titre-app {
  color: $blueCompactColor;
  justify-content: center;
  align-items: center;
  font-size: x-small;
  display: block;
}

.bar-nav-menu {
  width: 100%;
}

.bar-nav-menu-cs {
  width: 40%;
}

.left-nav {
  //background-color: #161b22;
  margin: 0rem !important;
}

.btn-bar-nav-menu {
  border: 0px;
  margin-left: 0.1rem !important;
  font-size: smaller;
}

.btn-nav-header {
  font-size: medium !important;
  color: #111111 !important;
}

.btn-bar-nav-menu:hover {
  border: 0px;
  background-color: rgba(217, 216, 217, 0.01) !important;
  color: #161b22 !important;
}

.btn-nav-header:hover {
  border: 0px;
  background-color: rgba(217, 216, 217, 0.01) !important;
  color: #9d9fa5 !important;
}

.btn-bar-nav-menu:focus {
  box-shadow: 0 0 0 0.2rem rgb(108 117 125 / 0%) !important;
}

.i-bar-nav-menu {
  margin: 0.5rem !important;
  color: $ooredooRedColor;
}

.active-link-act {
  background-color: $ooredooRedColor !important;
  color: rgba(251, 246, 255, 0.97) !important;
}

.active-link-act .i-bar-nav-menu {
  color: rgba(251, 246, 255, 0.97) !important;
}

.active-link-act-header {
  color: $ooredooRedColor !important;
}

.log-grid {
}

.name {
  text-shadow: 4px 3px 0px rgba(255, 34, 43, 0.35),
    9px 8px 0px rgba(241, 241, 241, 0.15);
  //font-style: italic;
  font-weight: bold;
  font-family: ooredooRegular;
  font-size: 0.82em;
  //  background: linear-gradient(to right, #36ecde, #000);
}

.div-log {
  margin-top: 0rem !important;
}

.p-button-warning {
  background-color: #d2d2d2 !important;
  border-color: #9d9fa5 !important;
}

.btn-login {
  height: 2.4rem !important;
}

.custom-control .custom-control-label {
  padding-top: 0.1rem !important;
}

.auto-select .form-control {
  background-color: rgba(0, 82, 204, 0) !important;
  border: 0px !important;
  //width: 34rem!important;
  width: 26em !important;
  padding-bottom: 0px !important;
}

// .form-group {
//   margin-bottom: 0rem !important;
// }

.select-right {
  margin-bottom: 0rem !important;
  // height: 2.4rem!important;
  border: 0px !important;
  width: 5em !important;
  background-color: rgba(220, 220, 220, 0.31) !important;
}

select:focus-visible {
  border: 0px !important;
  background-color: rgba(220, 220, 220, 0.31) !important;
}

select:focus {
  border: 0px !important;
  background-color: rgba(220, 220, 220, 0.31) !important;
}

.select-option {
  background-color: rgba(220, 220, 220, 0.31) !important;
}

.footer-log {
  margin: 0 30%;
  width: 60%;
  text-align: right;
  display: flex;
  justify-content: right;
  align-items: self-end;
  height: 2vh;
}

.border-t {
  border: 0.02rem groove rgba(1, 4, 9, 0.08) !important;
  border-radius: 0.7rem !important;
  padding: 0.8rem !important;
}

.p-toast-top-right {
  top: 70px !important;
  right: 20px !important;
}

.p-toast {
  width: 20rem !important;
}

.alert {
  padding: 0.3rem 1.05rem !important;
}

.text-center-element {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70%;
}

.p-button-sm-v {
  font-size: 0.8rem !important;
}

.container,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  max-width: 100%;
  margin: 0px;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.container-global {
  font-family: "ooredoobold";

  margin-top: 3.38rem !important;
  padding-right: 0px !important;
  overflow: scroll;
  width: 100% !important;
}

.container-left {
  padding-top: 0rem;
  border-radius: 0rem;
  color: #fcfcfc;
  min-height: 88vh !important;
}

.container-right {
  border-radius: 0rem 0.5rem 0rem 0rem !important;
  padding-left: 0.5rem !important;
  padding-right: 0rem !important;
  border-left: 2mm ridge rgba(223, 227, 233, 0.17) !important;
  min-height: 88vh !important;
  overflow: scroll;
}

.p-splitter .p-splitter-gutter {
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  background: rgba(0, 0, 0, 0.04);
  display: none !important;
}

.p-splitter {
  padding-top: 3.7rem !important;
}

.bas-page {
  position: absolute;
  width: 80%;
  bottom: 80px;
  right: 1%;
}

form {
  // height: 100% !important;
}

.titre-bar-slide {
  //background-color: #51c53a;
  width: 100%;
  text-transform: uppercase;
  font-size: 0.7rem;
  color: $ooredooRedColorfonc !important;
  height: 2rem;
  padding: 0.5rem;
}

.row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.logo-bar-slide {
  font-size: 0.8rem;
}

.ts-bt {
  width: 100%;
  margin-top: 0.1rem !important;
  text-align: left;
  color: black;
  background-color: #d0c9c947;
  border-radius: 0.3rem !important;
}

.ts-bt:hover {
  background-color: #8e8e8e1f !important;
}

//tab columns header
.p-columns-large {
  width: 10rem !important;
}

.p-columns-x-large {
  width: 16rem !important;
}
.p-columns-midle {
  width: 8rem !important;
}
.p-columns-small {
  width: 6rem !important;
}

.p-columns-x-small {
  width: 4rem !important;
}

.p-column-title {
  font-size: 14px;
}

//button cs icon
.p-button-icon-cs {
  color: rgba(73, 73, 73, 0.63) !important;
  background: rgba(203, 189, 189, 0);
  border: 0 none;
  padding: 0.1rem 0.1rem;
  font-size: 1.1rem;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s,
    box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  border-radius: 3px;
  min-width: 1rem;
}

.p-button-icon-csc {
  color: #ffffff;
  background: #ed1c24 !important;
  border: 1px solid #ed1c24 !important;
  font-size: 0.7rem;
  min-width: 1rem;
  border-radius: 50%;
  padding: 0.43rem 0.43rem !important;
  margin-left: 0.5rem !important;
}

.p-button-icon-csc-header {
  background: rgba(210, 210, 210, 0.42) !important;
  border: 1px solid rgb(0 0 0 / 16%) !important;
  border-radius: 50%;
  padding: 0.33rem 0.63rem !important;
  margin-right: -18px;
  text-transform: uppercase;
}

.cs-bag {
  background: #b1b1b1 !important;
  border: 1px solid #b1b1b1 !important;
  border-radius: 20%;
}

.p-button-icon-csc .pi {
  font-size: 0.8rem !important;
  align-content: center;
  text-align: center;
}

.p-button-icon-csc:enabled:focus {
  color: #000000 !important;
  background: rgba(172, 172, 172, 0) !important;
}

.p-button-icon-csc:hover {
  color: #ffffff !important;
  background: #c9181f !important;
}

.cs-bag:hover {
  background: #7c7c7c !important;
}

.cs-bag:enabled:focus {
  background: rgba(177, 177, 177, 0.71) !important;
}

.p-button-icon-cs:enabled:focus {
  color: #000000 !important;
  background: rgba(172, 172, 172, 0) !important;
}

.p-button-icon-cs:hover {
  color: #000000 !important;
  background: rgba(172, 172, 172, 0) !important;
}

.cs-bag-input {
  border-radius: 20%;
  background-color: rgba(172, 172, 172, 0) !important;
  color: #2196f3 !important;
  border-color: rgba(172, 172, 172, 0) !important;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
}

.cs-bag-input:hover {
  background-color: rgba(33, 157, 253, 0.24) !important;
}

//input text css
.p-inputtext:enabled:hover {
  border-color: #6366f1 !important;
  cursor: pointer;
}

.p-inputtext:enabled:focus {
  outline: 0 none !important;
  outline-offset: 0 !important;
  box-shadow: 0 0 0 0.2rem #c7d2fe !important;
  border-color: #6366f1 !important;
}

.p-inputtext {
  font-family: "ooredoobold";
  font-size: 0.8rem !important;
}

label {
  left: 0.75rem;
  color: #6c757d;
  transition-duration: 0.2s;
  font-size: small !important;
  font-weight: normal !important;
}

.p-float-label input:focus ~ label,
.p-float-label .p-inputwrapper-focus ~ label {
  color: #6c757d !important;
  font-size: small !important;
}

.p-error {
  border-color: #ff363b !important;
  color: #ff363b !important;
}

.p-valid {
  border-color: #6366f1 !important;
  //color: rgba(172, 172, 172, 0.7) !important;
}

.p-float-inpuText {
  margin-top: 0.7rem;
}

.p-float-inpuText-erreur {
  margin-top: 0.01rem;
}

.container-frm-sb {
  justify-content: center;
  align-content: center;
  width: 100%;
}

.container-frm-sb-min {
  width: 50%;
}

.col-sc {
  margin-left: 10rem;
  margin-top: 1rem;
}

.col-sc-small {
  margin-left: 5rem;
  margin-top: 1rem;
}

.cs-icon-light {
  float: right;
}

.cs-icon-light-none {
  float: none;
  text-align: left;
  margin-left: 0.5rem !important;
}

.cs-icon-fa {
  color: #c21c24 !important;
  font-size: 1.3rem;
}

.container_hide {
  width: 5% !important;
  float: left;
}

.container_show {
  width: 17% !important;
  float: left;
}

.container_hide_right {
  width: 95% !important;
  float: right;
}

.container_show_right {
  width: 83% !important;
  float: right;
}

.p-inputtext-size {
  width: 25rem !important;
}

.p-inputtext-size .p-password-input {
  width: 100% !important;
}

.p-valid .p-password-input {
  border-color: #6366f1 !important;
}

.p-error .p-password-input {
  border-color: #ff363b !important;
  color: #ff363b !important;
}

.div-sep-css.p-divider.p-divider-vertical {
  margin: 0 0rem !important;
  padding: 0.1rem !important;
  height: 0.05rem !important;
  background-color: #8e8e8e !important;
  width: 100% !important;
  opacity: 0.2;
}

.div-sep-css-filter.p-divider.p-divider-vertical {
  margin: 0rem 0rem 0.3rem 0rem !important;
  padding: 0.05rem !important;
  height: 0.02rem !important;
  background-color: #8e8e8e !important;
  width: 100% !important;
  opacity: 0.2;
}

.div-sep-css-spr.p-divider.p-divider-vertical {
  margin-top: 0.4rem !important;
  margin-bottom: 0.4rem !important;
  margin-left: 0rem !important;
  padding: 0.05rem !important;
  height: 0.2rem !important;
  background-color: rgba(32, 4, 6, 0.45) !important;
  width: 100% !important;
  opacity: 0.3;
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: rgba(223, 227, 233, 0.17) !important;
}

.p-component-overlay {
  background-color: rgb(113 113 126 / 11%) !important;
}

//out-put-text
.out-put-text {
  display: flex;
  justify-content: space-between;
  display: inline;
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
}

.out-put-text-label {
  margin-right: 1rem;
  font-size: small;
  font-family: ooredooRegular !important;
}

.out-put-text-value {
  text-decoration: underline;
  color: #8e8e8e;
  font-size: small;
}

.out-put-text-value-ovri {
  text-decoration: underline;
  color: #ac1f1a !important;

  font-size: small;
}

/*
.p-fileupload {
  font-size: small !important;
  width: 100%;
  margin-top: 0.5rem;
}

.p-fileupload .p-fileupload-buttonbar {
  background: #ffffff;
  padding: 0.05rem;
  border: 0px solid #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  border-bottom: 0 none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-fileupload .p-fileupload-buttonbar .p-button {
  margin-right: 0.5rem;
  background-color: #dcdcdc1c!important;
  color: #fc1f27;
  font-size: x-small;
}

.p-fileupload .p-fileupload-content {
  background: #ffffff;
  padding: 1rem 1rem;
}

.p-fileupload .p-fileupload-buttonbar .p-button :disabled {
  color: rgba(0, 0, 0, 0.38) !important;
  opacity: 1;
  font-size: x-small;

}

.p-fileupload-row {
  display: flex;
  align-items: center;
  font-size: small;
}

.p-fileupload .p-fileupload-row > div {
  padding: 0.05rem 0.05rem;
}

.p-fileupload .p-fileupload-row > div > img {
  width: 2rem !important;
}

.p-fileupload-content {
  background: #ffffff;
  padding: 0.2rem 0.5rem;
  border: 1px solid #e0e0e0;
}


.p-fileupload-row {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}

.p-fileupload .p-fileupload-row > div {
  padding: 0.05rem 0.05rem;
}

.p-fileupload .p-fileupload-row > div > img {
  width: 2rem !important;
}

.p-fileupload-content {
  background: #ffffff;
  padding: 0.2rem 0.5rem;
  border: 1px solid #e0e0e0;
}
*/

.filter-cs {
  width: 100%;
  border: 0rem groove rgba(1, 4, 9, 0.08) !important;
  border-radius: 0.2rem !important;
  margin-right: 0.2rem !important;
  padding-right: 1px !important;
  padding-left: 1px !important;
  margin-left: 1px !important;
  margin-bottom: 1px !important;
}

.filter-element {
  margin-top: 0.2rem;
  padding-right: 5px;
  padding-left: 5px;
  // max-height: 1rem!important;
}

.filter-element-btn {
  float: right;
  margin-left: 0.5rem;
}

.p-filter-tab-cs {
  height: 2rem !important;
  width: 100%;
}

.p-datatable-tbody {
  font-family: ooredooRegular;
  font-size: 1rem !important;
  font-weight: 400 !important;
}
.p-filter-dropd-cs {
  font-family: ooredooRegular;
  font-size: 0.75rem !important;
  margin-left: 0.9rem !important;
}

/*
.p-button-icon-only {
color: #ac1f1a !important;
background-color: #dcdcdc1c !important;
border: 1px solid #dcdcdc1c !important;
font-size: 0.3rem !important;
min-width: 1rem !important;
border-radius: 50% !important;
padding: 0.43rem 0.43rem !important;
margin-left: 0.5rem !important;
}
*/
.active-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
  letter-spacing: 0.3px;
}
.active-badge.status-enabled {
  background: #c8e6c9;
  color: #256029;
}
.active-badge.status-disabled {
  background: #feedaf;
  color: #8a5340;
}
.active-badge.status-approved {
  background: #c8e6c9;
  color: #256029;
}
.active-badge.status-rejected {
  background: #feedaf;
  color: #8a5340;
}

/* ScrollTopDemo.css */

.p-scrolltop.p-link {
  width: 2rem;
  height: 2rem;
  border-radius: 4px;
  background-color: #ed1c24;
}
.custom-scrolltop:hover {
  background-color: #c9181f !important;
}

.custom-scrolltop .p-scrolltop-icon {
  font-size: 1rem;
  color: white;
}
